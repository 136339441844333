// apiHelper.js
import React, { useState } from 'react';
import { Alert, Snackbar, CircularProgress, IconButton, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import successIcon from '../requesthistory/successIcon.png';
import errorIcon from '../requesthistory/alerterrorIcon.png';
import warningIcon from '../requesthistory/warning.png';
import infoIcon from '../requesthistory/infoicon.png';
import { useRequestHistory } from '../context/RequestHistoryContext';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import config from '../config';
import './alertStyles.css';
import { useDisableDuration } from '../context/DisableDurationContext';

export const useSnackbarWithApiRequest = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [icon, setIcon] = useState(successIcon);
  const [loading, setLoading] = useState(false);
  const { disableDuration, setDisableDuration } = useDisableDuration();
  const { openModal } = useRequestHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
    if (severity === 'success') openModal();
  };

  const showSnackbar = (message, severity, icon, title) => {
    setMessage(message);
    setSeverity(severity);
    setIcon(icon);
    setOpen(true);
    setTitle(title);
  };

  const sendBonusRequest = async (bonus_id) => {
    if (disableDuration) return;
    setLoading(true);
    const user_id = getUserIdFromUrl();
    const token = await executeRecaptcha('bonus_talep_first');

    try {
      const response = await fetch(`${config.apiBaseUrl}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          process_id: bonus_id,
          user_id: user_id,
          recaptcha: token,
        }),
      });

      const result = await response.json();
      if (result.status === true) {
        showSnackbar(`${result.message}`, 'success', successIcon, 'Başarılı');
      } else if (result.status === 3) {
        showSnackbar(`${result.message}`, 'warning', warningIcon, 'Uyarı');
      } else if (result.status === 4) {
        showSnackbar(`${result.message}`, 'info', infoIcon, 'Altyapı Hatası');
      } else {
        const match = result.message.match(/(\d+)\s*saniye/);
        if (match) {
          const seconds = parseInt(match[1], 10);
          setDisableDuration(seconds);
          showSnackbar(result.message, 'warning', warningIcon, 'Bekleme Süresi');
        } else {
          showSnackbar(result.message, 'error', errorIcon, 'Reddedildi');
        }
      } 
    } catch (error) {
      showSnackbar(`API isteğinde hata: ${error.message}`, 'error', errorIcon);
    } finally {
      setLoading(false);
    }
  };

  const SnackbarAlert = (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        icon={<img src={icon} alt={severity === 'success' ? 'Success' : 'Error'} className="snackbar-alert-icon" />}
        className="snackbar-alert"
        action={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CircularProgress
              size={30}
              className="circular-progress"
              sx={{
                color: severity === 'success'
                  ? 'green'
                  : severity === 'error'
                    ? 'red'
                    : severity === 'info'
                      ? 'blue'
                      : severity === 'warning'
                        ? 'orange'
                        : 'gray',
              }}
            />
            <IconButton
              size="medium"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </div>
        }
      >
        <strong className="snackbar-title">{title}</strong>
        <strong className="snackbar-message">{message}</strong>
      </Alert>
    </Snackbar>
  );

  const LoadingBackdrop = (
    <Backdrop open={loading} style={{ zIndex: 1300 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  return { SnackbarAlert, LoadingBackdrop, sendBonusRequest, disableDuration };
};

export const getUserIdFromUrl = () => {
  const url = window.location.href;
  const userId = url.split('/').pop();
  return userId || "";
};
