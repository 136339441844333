import React from "react";
import '../css/MaintenanceMode.css'; // CSS dosyasını ekliyoruz
import { useStyles } from "../context/StyleContext";

const MaintenanceMode = () => {
    const styles = useStyles();
  return (
    <div className="maintenance-mode" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
      <h2>Otobonus Sistemi Şu An Bakımdadır</h2>
      <p>Sistemimizi sizin için daha iyi hale getirmek için geçici olarak bakımdayız. En kısa sürede geri döneceğiz.</p>
      <a 
          href="https://hookdijital.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          style={{marginTop: 30}}
        >
          <img src="./images/image7.png" alt="Hook logo" className="hook-logo" />
        </a>
    </div>
  );
};

export default MaintenanceMode;
