const config = {
    development: {
      apiBaseUrl: 'https://sohobonus.website/api/',
      appName: 'OrisBetBonus',
      jsonUrl: 'https://sohobonus.website/panel-data.json',
      cssJsonUrl: 'https://sohobonus.website/configCSS.json'
    },
    production: {
      apiBaseUrl: 'api/',
      appName: 'OrisBetBonus',
      jsonUrl: '/panel-data.json',
      cssJsonUrl: '/configCSS.json'
    },
  };
  
  const env = 'production';
  export default config[env];
  