import React from 'react';
import doubleBonus from '../requesthistory/doublebonus.png';
import CloseIcon from '@mui/icons-material/Close';
import '../css/DoubleBonus.css';
import { useSnackbarWithApiRequest } from '../helper/apiHelper';
import { useStyles } from '../context/StyleContext';
import { useDisableDuration } from '../context/DisableDurationContext';

export default function DoubleBonus({ closeModal, bonusData }) {
  const { SnackbarAlert, sendBonusRequest, LoadingBackdrop } = useSnackbarWithApiRequest();
  const styles = useStyles();
  const {disableDuration} = useDisableDuration();

  const handleBonusClick = (bonusId) => {
    sendBonusRequest(bonusId); // API isteği burada yapılır, Snackbar merkezden gösterilir
  };

  return (
    <div className="double-bonus" style={{ background: styles.MultipleBonusDialogStyles?.Dialog?.backgroundColor || "#1d1d1d" }}>
      <div className="DoubleBonusheader" style={{ color: styles.MultipleBonusDialogStyles?.DialogHeader?.TextColor || "white" }} >
        <span>Lütfen Bonus Seç</span>
        <CloseIcon
          onClick={closeModal}
          style={{ cursor: 'pointer' }}
          className='DoubleBonusCloseIcon'
        />
      </div>

      <div className="empty-state1">
        <div className="modal-body">
          <img src={doubleBonus} alt="bonus" className="bonus-icon1" />
          <p>
            {bonusData.length > 2 ? (
              <>
                <span style={{ color: styles.MultipleBonusDialogStyles?.SubText?.TextColor || "#959595" }}> Bu bonus çok seçeneklidir lütfen </span>
                <br />
                <span className="empty-subtext1" style={{ color: styles.MultipleBonusDialogStyles?.SubText?.TextColor || "#959595" }}>almak istediğiniz bonusu seçiniz!</span>
              </>
            ) : (
              <>
                Bu bonus çift seçeneklidir lütfen <br />
                <span className="empty-subtext1" style={{ color: styles.MultipleBonusDialogStyles?.SubText?.TextColor || "#959595" }}>almak istediğiniz bonusu seçiniz!</span>
              </>
            )}
          </p>
          <div className="buttons">
            <button
              className="button-primary"
              style={{
                backgroundColor: disableDuration ? '#ccc' : styles.MultipleBonusDialogStyles?.Button1?.backgroundColor || "#e5b645",
                color: disableDuration ? '#666' : styles.MultipleBonusDialogStyles?.Button1?.TextColor || "#25282b"
              }}
              onClick={() => handleBonusClick(bonusData[0].bonus_id)}
              disabled={Boolean(disableDuration)}
            >
              {disableDuration ? `Lütfen ${disableDuration} saniye bekleyin` : bonusData[0].bonus_name}
            </button>

            <span className="separator" style={{ color: styles.MultipleBonusDialogStyles?.Separator?.TextColor || "#cacaca" }}>veya</span>

            <button
              className="button-secondary"
              style={{
                backgroundColor: disableDuration ? '#ccc' : styles.MultipleBonusDialogStyles?.Button2?.backgroundColor || "#1d1d1d",
                color: disableDuration ? '#666' : styles.MultipleBonusDialogStyles?.Button2?.TextColor || "#e5b645",
                borderColor: disableDuration ? '#999' : styles.MultipleBonusDialogStyles?.Button2?.BorderColor || "#e5b645"
              }}
              onClick={() => handleBonusClick(bonusData[1].bonus_id)}
              disabled={Boolean(disableDuration)}
            >
              {disableDuration ? `Lütfen ${disableDuration} saniye bekleyin` : bonusData[1].bonus_name}
            </button>

            {bonusData.length > 2 && (
              <>
                <span className="separator" style={{ color: styles.MultipleBonusDialogStyles?.Separator?.TextColor || "#cacaca" }}>veya</span>
                <button
                  className="button-primary"
                  style={{
                    backgroundColor: disableDuration ? '#ccc' : styles.MultipleBonusDialogStyles?.Button1?.backgroundColor || "#e5b645",
                    color: disableDuration ? '#666' : styles.MultipleBonusDialogStyles?.Button1?.TextColor || "#25282b"
                  }}
                  onClick={() => handleBonusClick(bonusData[2].bonus_id)}
                  disabled={Boolean(disableDuration)}
                >
                  {disableDuration ? `Lütfen ${disableDuration} saniye bekleyin` : bonusData[2].bonus_name}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {SnackbarAlert}
      {LoadingBackdrop}
    </div>
  );
}
