import React, { useState, useEffect } from "react";
import '../css/RequestHistoryDialog.css';
import check from '../requesthistory/check.png';
import iconError from '../requesthistory/error.png';
import CloseIcon from '@mui/icons-material/Close';
import emptyIcon from '../requesthistory/emptyIcon.png';
import { getUserIdFromUrl } from '../helper/apiHelper';
import { useRequestHistory } from "../context/RequestHistoryContext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { CircularProgress } from "@mui/material";
import config from "../config";
import { useBonus } from "../context/BonusContext";

export default function RequestHistory() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { closeModal } = useRequestHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const userId = getUserIdFromUrl();
  const {wheelURL} = useBonus();



  useEffect(() => {
    const fetchRequestHistory = async () => {
      if (!executeRecaptcha) {
        console.log('reCAPTCHA yüklenemedi');
        return;
      }

      try {
        const token = await executeRecaptcha('taleplerim');
        const response = await fetch(`${config.apiBaseUrl}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            process_id: 'user-info',
            user_id: getUserIdFromUrl(),
            recaptcha: token,
          }),
        });

        const result = await response.json();

        if (result.status && result.bonus) {
          const formattedRequests = result.bonus.map((bonus) => ({
            date: formatDate(bonus.date),
            bonus_name: bonus.bonus_name,
            notes: bonus.notes,
            status: bonus.status,
            statusColor: bonus.status === "Onaylandı" ? "#25CE74" : "rgb(221, 83, 83)",
            icon: bonus.status === "Onaylandı" ? check : iconError,
          }));

          setRequests(formattedRequests);
        } else {
          setError("Veri alınamadı");
        }
      } catch (err) {
        setError("Bir hata oluştu: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRequestHistory();
  }, []);

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const date = new Date(dateString.replace(" ", "T"));
    return new Intl.DateTimeFormat('tr-TR', options).format(date);
  };

  const handleNavigate = () => {
    window.location.href = `${wheelURL}${userId}`;
  };

  if (loading || error) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
        <CircularProgress color="secondary" />
        {error && <p style={{ color: 'red', marginTop: '10px' }}>Hata: {error}</p>}
      </div>
    );
  }

  return (
    <div className="testOne">
      <div className="RequestHistoryheader">
        <span>Talep Geçmişin</span>
        <CloseIcon
          onClick={closeModal}
          style={{ cursor: 'pointer' }}
          className='requestHistoryCloseIcon'
        />
      </div>

      {requests.length === 0 ? (
        <div className="empty-state">
          <img src={emptyIcon} alt="No Requests" className="empty-icon" />
          <p>
            Talep geçmişin <br />
            <span className="empty-subtext">bulunmamaktadır. Lütfen talep ediniz.</span>
          </p>
        </div>
      ) : (
        requests.map((request, index) => (
          <div className="card" key={index}>
            <div className="card-content">
              <div className="date-section">
                <span className="date">{request.date}</span>
              </div>
              <div className="line-section">
                <hr className="line" />
              </div>
              <div className="title-section">
                <span className="title">{request.bonus_name}</span>
              </div>
              <div className="line-section">
                <hr className="line" />
              </div>
              <div className="status-section">
                <span className="status" style={{ color: request.statusColor }}>
                  {request.notes}
                </span>

                {/* Çarka Git Butonu */}
                {request.bonus_name.toLowerCase().includes("çark") && request.status === "Onaylandı" && (
                  <button className="navigate-wheel-button" onClick={handleNavigate} >
                    Çarka Git
                  </button>
                )}
              </div>
              <div className="status-icon">
                <img src={request.icon} alt="Status Icon" />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
