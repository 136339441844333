import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import RequestHistoryButton from "./RequestHistoryButton";
import Tabs from "./Tabs";
import { getUserIdFromUrl } from "../helper/apiHelper";
import { CircularProgress } from "@mui/material";
import BonusCards from "./BonusCards";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import config from "../config";
import { useStyles } from "../context/StyleContext";
import { DisableDurationProvider } from "../context/DisableDurationContext";
import MaintenanceMode from "./MaintenanceMode"; // Yeni bileşeni import ediyoruz
import '../css/HomePage.css';
import { useBonus } from "../context/BonusContext";

const HomePage = () => { 
    const [userName, setUserName] = useState('');
    const [selectedTab, setSelectedTab] = useState('Tümü');
    const [loading, setLoading] = useState(true);
    const userId = getUserIdFromUrl();
    const { executeRecaptcha } = useGoogleReCaptcha(); 
    const styles = useStyles();
    const {updateMode} = useBonus();

    const fetchUserName = useCallback(async () => {
      let success = false;
      let attempts = 0;
      const maxAttempts = 4;
      const timeout = 5000;

      const fetchWithTimeout = (url, options, timeout) => {
        return Promise.race([
          fetch(url, options),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Request timeout")), timeout)
          ),
        ]);
      };

      while (!success && attempts < maxAttempts) {
        if (!executeRecaptcha) {
          attempts += 1;
          await new Promise((resolve) => setTimeout(resolve, 2000));
          continue;
        }

        try {
          const token = await executeRecaptcha("forusername");
          const response = await fetchWithTimeout(
            `${config.apiBaseUrl}`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                process_id: "user-name",
                user_id: userId,
                recaptcha: token,
              }),
            },
            timeout
          );

          const result = await response.json();

          if (result.status && result.full_name) {
            setUserName(result.full_name);
            success = true;
            setLoading(false);
          } else if (result.message === "Bilinmeyen kullanıcı") {
            setLoading(true);
            console.log(result.message);
            return;
          } else {
            setUserName("Kullanıcı");
            setLoading(true);
          }
        } catch (error) {
          console.error("İstek sırasında hata oluştu:", error);
          setUserName("Kullanıcı");
          setLoading(true);
        }

        attempts += 1;
      }
    }, [executeRecaptcha, userId]);

    useEffect(() => {
      fetchUserName();
    }, [fetchUserName]);

    const handleTabChange = (tab) => {
      setSelectedTab(tab);
    };

    if (updateMode) {
      return <MaintenanceMode />;
    }

    if (loading || !userId) {
      return (
        <div className="loading-container" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <div className="homepage-container" style={{ backgroundColor: styles.HomePage?.backgroundColor || "#282828ff" }}>
        <Header userName={userName} />

        <div className="tabs-request-container">
          <Tabs onTabChange={handleTabChange} selectedTab={selectedTab} />
          <RequestHistoryButton />
        </div>
        
        <DisableDurationProvider>
          <div className="bonus-card-container-2">
            <BonusCards selectedTab={selectedTab} />
          </div>
        </DisableDurationProvider>    

        <a 
          href="https://hookdijital.com/" 
          target="_blank" 
          rel="noopener noreferrer"
          className="hook-link"
        >
          <img src="./images/image7.png" alt="Hook logo" className="hook-logo" />
        </a>
      </div>
    );
};

export default HomePage;
