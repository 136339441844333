// DisableDurationContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const DisableDurationContext = createContext();

export const DisableDurationProvider = ({ children }) => {
  const [disableDuration, setDisableDuration] = useState(null);

  useEffect(() => {
    if (disableDuration && disableDuration > 0) {
      const timer = setInterval(() => {
        setDisableDuration((prev) => {
          if (prev && prev > 1) {
            return prev - 1;
          } else {
            clearInterval(timer); // Timer'ı temizle
            return null; // Süre bitince disableDuration'u sıfırla
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [disableDuration]);

  return (
    <DisableDurationContext.Provider value={{ disableDuration, setDisableDuration }}>
      {children}
    </DisableDurationContext.Provider>
  );
};

export const useDisableDuration = () => useContext(DisableDurationContext);
