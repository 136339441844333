import React, { createContext, useState, useEffect, useContext } from 'react';
import config from '../config';


// Context oluştur
const BonusContext = createContext();

// Bonus verisini sağlayacak context provider
export const BonusProvider = ({ children }) => {
  const [bonusData, setBonusData] = useState([]); // Bonus verilerini saklayacak state
  const [tabs, setTabs] = useState([]);
  const [loading, setLoading] = useState(true); // Yüklenme durumu
  const [error, setError] = useState(null); // Hata durumu
  const [wheelURL, setWheelURL] = useState("");
  const [updateMode, setUpdateMode] = useState(false);

  // Bonus verisini URL'den çekmek için useEffect kullanıyoruz
  useEffect(() => {
    const fetchBonusData = async () => {
      try {
        const response = await fetch(`${config.jsonUrl}`);
        const data = await response.json();
        setBonusData(data.talep_data); // Bonus verisini state'e kaydet
        setTabs(data.bonus_type_tabs);
        setWheelURL(data.spinWheelURL);
        setUpdateMode(data.updateMode);
        setLoading(false); // Yüklenme tamamlandı  
      } catch (err) {
        console.error('Veri çekme hatası:', err);
        setError('Veri çekme hatası');
        setLoading(false);
      }
    };

    fetchBonusData();
  }, []); // Bir kez çalışacak

  // Bonus verisi, loading durumu ve hata bilgisini sağlayan context
  return (
    <BonusContext.Provider value={{ bonusData, loading, error, tabs, wheelURL, updateMode }}>
      {children}
    </BonusContext.Provider>
  );
};

// Context'teki veriye erişmek için kullanılacak özel hook
export const useBonus = () => useContext(BonusContext);
